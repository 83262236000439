import { IPatient } from "../../types/types"

interface IPatientListCard {
  patient: IPatient
  changePatientId: () => void
  selected?: boolean
}

const PatientListCard = (props: IPatientListCard) => {
  return (
    <button
      className={`flex flex-col rounded-xl px-4 py-2 transition ease-in-out duration-1000 ${
        props.selected ? "bg-sumo-550" : "bg-sumo-50"
      }`}
      onClick={props.changePatientId}
    >
      <div className="flex flex-col text-left">
        <div className="font-bold text-sumo-500">
          {props.patient.name + " " + props.patient.surname}
        </div>
        <div className="font-normal text-slate-500 text-sm">
          Date d'opération{" "}
          {new Date(props.patient.surgery_date).toLocaleDateString("fr")}
        </div>
      </div>
    </button>
  )
}

export default PatientListCard
