import { IPatientSurvey, IQuestion, ISurvey } from "../../types/types"

interface ISurveyModal {
  closeModal: () => void
  survey: ISurvey | undefined
  patientSurvey: IPatientSurvey
}
const findSubmissionValueToQuestion = (
  question: IQuestion,
  patientSurvey: IPatientSurvey
) => {
  const ans = patientSurvey.submission.answers.find(
    (_ans) => _ans.question_id === question.id
  )
  if (ans && question) {
    if (question.kind !== "NUMBER") {
      return question.options?.find((_opt) => _opt.value === ans?.value)?.label
    } else return ans.value
  }
}

const SurveyModal = (props: ISurveyModal) => {
  const renderQuestionWithAnswer = (question: IQuestion) => {
    const ans = findSubmissionValueToQuestion(question, props.patientSurvey)

    return (
      <>
        <div className="text-slate-500 font-semibold">{question.content}</div>
        {ans && (
          <div className="text-sumo-550 font-semibold pt-1 pb-4">{ans}</div>
        )}
      </>
    )
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t-xl">
              <div>
                <h3 className="text-sumo-500 text-xl font-semibold">
                  {props.survey?.content}
                </h3>
                <h3 className="text-slate-500 text-m font-normal pt-3">
                  {new Date(
                    props.patientSurvey.submission.created_at
                  ).toLocaleDateString("fr")}
                </h3>
              </div>
              <button
                className="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={props.closeModal}
              >
                <img
                  src="/images/close-circle.svg"
                  style={{ height: "25px" }}
                  alt="close"
                  loading="lazy"
                />
              </button>
            </div>
            <div className="relative p-6 flex-auto max-h-[600px] overflow-y-auto ">
              {props.survey?.questions.map((_question) =>
                renderQuestionWithAnswer(_question)
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default SurveyModal
