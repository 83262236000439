import { useEffect, useState } from "react"
import { Line } from "react-chartjs-2"
import { IAnalyze, IPatientSurvey, IScore } from "../../types/types"

interface IPatientCard {
  patientId: string | undefined
  patientName: string
  patientSurname: string
  surgeryDate: Date
  patient_surveys: Array<IPatientSurvey> | undefined
  preOpSurveys: Array<IPatientSurvey> | undefined
  qOLSurveys: Array<IPatientSurvey> | undefined
  postOpSurveys: Array<IPatientSurvey> | undefined
  patientScores: { [patientId: string]: Array<IScore> } | undefined
  predictions: { [patientId: string]: Array<IAnalyze> } | undefined
}

const PatienCard = (props: IPatientCard) => {
  const [chartData, setChartData] = useState({
    labels: [] as Array<string>,
    datasets: [
      {
        label: "Évolution de la qualité de vie",
        data: [] as Array<number>,
        backgroundColor: ["rgba(6, 214, 160, 1)"],
        borderColor: ["rgba(6, 214, 160, 1)"],
        borderWidth: 2,
        tension: 0.8
      }
    ]
  })
  useEffect(() => {
    if (props.patientId)
      if (props.patientScores && props.patientScores[props.patientId]) {
        const _data = props.patientScores[props.patientId].sort((a, b) => {
          const a_date = new Date(a.created_at)
          const b_date = new Date(b.created_at)
          return a_date.valueOf() - b_date.valueOf()
        })

        const _labels = _data.map((_dat) => {
          return new Date(_dat.created_at).toLocaleDateString("fr")
        })

        const _odi_values = _data.map((_dat) => {
          return 100 - _dat.life_quality
        })

        const _pain_values = _data.map((_dat) => {
          return _dat.pain_management
        })

        const _walk_values = _data.map((_dat) => {
          return _dat.walking
        })

        setChartData({
          labels: _labels,
          datasets: [
            {
              label: "ODI",
              data: _odi_values,
              backgroundColor: ["rgba(6, 214, 160, 1)"],
              borderColor: ["rgba(6, 214, 160, 1)"],
              borderWidth: 2,
              tension: 0.4
            },
            {
              label: "Gestion Douleur",
              data: _pain_values,
              backgroundColor: ["rgba(239, 71, 111, 1)"],
              borderColor: ["rgba(239, 71, 111, 1)"],
              borderWidth: 2,
              tension: 0.4
            },
            {
              label: "Autonomie Physique",
              data: _walk_values,
              backgroundColor: ["rgba(33, 134, 215, 1)"],
              borderColor: ["rgba(33, 134, 215, 1)"],
              borderWidth: 2,
              tension: 0.4
            }
          ]
        })
      } else {
        setChartData({
          labels: [],
          datasets: []
        })
      }
  }, [props.patientId, props.patientScores])

  return (
    <div className="flex flex-col px-6 py-10 w-1/2 rounded-xl shadow-2xl bg-sumo-50">
      <div className="flex flex-col w-full">
        <div className="text-slate-900 font-semibold pb-4">
          ID {props.patientId}
        </div>
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/2">
            <div className="font-semibold text-slate-500">
              {props.patientName + " " + props.patientSurname}
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <div className="flex flex-row">
              <div className="w-1/2 font-semibold text-slate-500">
                Opération le
              </div>
              <div className="text-slate-900 font-semibold w-1/2 text-start">
                {props.surgeryDate.toLocaleDateString("fr")}
              </div>
            </div>
            {props.predictions &&
              props.patientId &&
              props.predictions[props.patientId] && (
                <div className="flex flex-row  mt-2 justify-center items-center">
                  <div className="w-1/2 font-semibold text-slate-500">
                    Prédiction
                  </div>
                  <div className="text-slate-900 font-semibold w-1/2 text-start">
                    {props.predictions[props.patientId][0].prediction === 1 ? (
                      <div className="w-10 h-5 bg-sumo-550 rounded-lg"></div>
                    ) : props.predictions[props.patientId][0].prediction ===
                      0 ? (
                      <div className="w-10 h-5 bg-sumo-800 rounded-lg"></div>
                    ) : (
                      <div className="w-10 h-5 bg-sumo-100 rounded-lg"></div>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full pt-6">
        <div className="flex flex-col w-1/2">
          <div className="text-sumo-500 font-semibold pb-4">QUESTIONNAIRES</div>
          <div className="flex flex-row">
            <div className="text-slate-500 font-semibold">
              <div className="pb-2">Total répondus</div>
              <div className="pb-2">Pré-opératoires répondus</div>
              <div className="pb-2">Qualité de vie répondus</div>
              <div className="pb-2">Post-opératoires répondus</div>
              <div className="pb-2">Scores calculés</div>
            </div>
            <div className="text-slate-700 font-bold pl-4 text-end">
              <div className="pb-2">{props.patient_surveys?.length}</div>
              <div className="pb-2">{props.preOpSurveys?.length}</div>
              <div className="pb-2">{props.qOLSurveys?.length}</div>
              <div className="pb-2">{props.postOpSurveys?.length}</div>
              <div className="pb-2">
                {props.patientScores &&
                  props.patientId &&
                  (props.patientScores[props.patientId]
                    ? props.patientScores[props.patientId].length
                    : 0)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/2">
          <div className="text-sumo-500 font-semibold pb-4">
            DERNIERS SCORES
          </div>
          <div className="flex flex-row">
            <div className="text-slate-500 font-semibold">
              <div className="pb-2">Date</div>
              <div className="pb-2">ODI</div>
              <div className="pb-2">Autonomie Physique</div>
              <div className="pb-2">Gestion de la Douleur</div>
            </div>
            {props.patientScores && props.patientId && (
              <div className="text-slate-700 font-bold pl-4 text-end">
                <div className="pb-2">
                  {props.patientScores[props.patientId]
                    ? new Date(
                        props.patientScores[props.patientId][
                          props.patientScores[props.patientId].length - 1
                        ].created_at
                      ).toLocaleDateString("fr")
                    : "N/A"}
                </div>
                <div className="pb-2">
                  {props.patientScores[props.patientId]
                    ? 100 -
                      props.patientScores[props.patientId][
                        props.patientScores[props.patientId].length - 1
                      ].life_quality
                    : "N/A"}
                </div>
                <div className="pb-2">
                  {props.patientScores[props.patientId]
                    ? props.patientScores[props.patientId][
                        props.patientScores[props.patientId].length - 1
                      ].physical_autonomy
                    : "N/A"}
                </div>
                <div className="pb-2">
                  {props.patientScores[props.patientId]
                    ? props.patientScores[props.patientId][
                        props.patientScores[props.patientId].length - 1
                      ].pain_management
                    : "N/A"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full pt-6">
        <div className="text-sumo-500 font-semibold">
          EVOLUTION QUALITÉ DE VIE
        </div>
        <div className="lg:w-[700px]">
          <Line
            data={chartData}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Évolution de la qualité de vie"
                },
                legend: {
                  position: "top"
                }
              },
              animation: {
                duration: 1000
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PatienCard
