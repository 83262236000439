import { useState } from "react"
import { useAuth } from "../../hooks/useAuth"

interface IProps {}

const ResetPasswordRequest = (props: IProps) => {
  const {
    requestPasswordReset,
    resetPasswordRequested,
    resetRequestPasswordReset,
    loading
  } = useAuth()
  const [inputs, setInputs] = useState({
    email: ""
  })

  const resetPasswordRequestForm = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    const email = inputs["email"]
    requestPasswordReset(email)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name
    const value = e?.target?.value
    setInputs((values) => ({ ...values, [name]: value }))
  }

  return (
    <div className="flex items-center justify-center bg-sumo-100 w-full h-screen">
      <div className="rounded-xl bg-sumo-50 shadow-2xl p-4 pt-4">
        <div className="flex items-center  justify-center my-4">
          <a href="/">
            <img
              src={"/images/sumo_logo_bare.png"}
              style={{ height: "80px" }}
              alt="RP Logo"
              loading="lazy"
              className="bg-sumo-500 rounded-3xl"
            />
          </a>
        </div>
        <h1 className="font-semibold text-2xl text-sumo-500  text-center mx-4 w-72">
          Réinitialisez votre mot de passe
        </h1>
        {!resetPasswordRequested ? (
          <div className="flex flex-col w-full">
            <h2 className="text-lg text-sumo-500 mt-8 w-72 text-center">
              Entrez votre email de connexion pour effectuer votre demande de
              réinitialisation de mot de passe
            </h2>
            <form className="mt-6" onSubmit={resetPasswordRequestForm}>
              {!loading ? (
                <>
                  <h2 className="font-semibold text-lg text-sumo-500 pb-2">
                    email
                  </h2>
                  <input
                    className="border-2 border-slate-300 rounded-xl w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none"
                    name="email"
                    type="email"
                    placeholder="email"
                    onChange={handleChange}
                  />
                </>
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src="/images/fade-stagger-circles.svg"
                    style={{ height: "100px" }}
                    alt="close"
                    loading="lazy"
                  />
                </div>
              )}
              <div className="flex justify-end items-end mt-6">
                <button
                  className="bg-sumo-550 hover:opacity-60 rounded-xl py-2 px-4 text-sumo-50 font-semibold"
                  type="submit"
                >
                  <p>Réinitialiser</p>
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <h2 className="text-lg text-sumo-500 mt-8 text-center mb-8 w-72">
              Un email vous a été envoyé avec les instructions de
              réinitialisation de votre mot de passe.
            </h2>
            <div className="text-base text-sumo-500 mt-8 text-center mb-8">
              Si vous n'avez pas reçu l'email, réessayez.
            </div>
            <div className="flex justify-end items-end mt-6">
              <button
                className="bg-sumo-550 hover:opacity-60 rounded-xl py-2 px-4 text-sumo-50 font-semibold"
                onClick={resetRequestPasswordReset}
              >
                <p>Réessayer</p>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetPasswordRequest
