import React from "react"
import { useAuth } from "../../hooks/useAuth"

const Header = () => {
  const { logout } = useAuth()
  return (
    <nav className="flex-no-wrap relative flex w-full items-center justify-between bg-sumo-500 py-2 shadow-dark-mild dark:bg-store-50 lg:flex-wrap lg:justify-start h-32">
      <div className="flex w-full flex-wrap items-center px-4">
        <div className="flex items-center justify-start">
          <div
            className="flex items-center lg:basis-auto"
            id="navbarSupportedContent1"
            data-twe-collapse-item
          >
            <a
              className="flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-500 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mb-0 lg:mt-0"
              href="/"
            >
              <img
                src={"/images/sumo_logo_bare.png"}
                style={{ height: "80px" }}
                alt="RP Logo"
                loading="lazy"
              />
            </a>
            <div className="ml-10 mt-1 hidden md:block">
              <a className="flex " href="/">
                <img
                  src="/images/stats.svg"
                  style={{ height: "25px" }}
                  alt="stats"
                  loading="lazy"
                />
                <div className="ml-2 text-sumo-50 ">Statistiques</div>
              </a>
            </div>
            <div className="ml-10 mt-1 hidden md:block">
              <a className="flex " href="/files">
                <img
                  src="/images/file-medical.svg"
                  style={{ height: "25px" }}
                  alt="RP Logo"
                  loading="lazy"
                />
                <div className="ml-2 text-sumo-50">Dossiers</div>
              </a>
            </div>
          </div>
        </div>{" "}
        <div className="flex flex-1 items-end justify-end mr-2">
          <button className="p-3" onClick={logout}>
            <img
              src={"/images/logout.svg"}
              style={{ height: "35px" }}
              alt="logout"
              loading="lazy"
            />
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Header
