import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthStatus, useAuth } from "../../hooks/useAuth"

interface IProps {}

const Connexion = (props: IProps) => {
  const navigate = useNavigate()
  const { status, login, loginError, setLoginError, loading } = useAuth()
  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  })
  const [passwordVisible, setPasswordVisible] = useState(false)

  const loginForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const email = inputs["email"]
    const password = inputs["password"]
    login(email, password)
  }

  useEffect(() => {
    if (status === AuthStatus.Authenticated) navigate("/")
  }, [status, navigate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (loginError) setLoginError(false)
    const name = e?.target?.name
    const value = e?.target?.value
    setInputs((values) => ({ ...values, [name]: value }))
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <div className="flex items-center justify-center bg-sumo-100 w-full h-screen">
      <div className="rounded-xl bg-sumo-50 shadow-2xl p-4 pt-4">
        <div className="flex items-center  justify-center my-4">
          <img
            src={"/images/sumo_logo_bare.png"}
            style={{ height: "80px" }}
            alt="RP Logo"
            loading="lazy"
            className="bg-sumo-500 rounded-3xl"
          />
        </div>
        <h1 className="font-semibold text-2xl text-sumo-500  text-center">
          Connectez-vous
        </h1>
        <form className="m-6" onSubmit={loginForm}>
          {!loading ? (
            <>
              <h2 className="font-semibold text-lg text-sumo-500">email</h2>
              <input
                className="border-2 border-slate-300 rounded-xl w-72 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none"
                name="email"
                type="email"
                placeholder="email"
                onChange={handleChange}
              />
              <h2 className="font-semibold text-lg text-sumo-500">
                mot de passe
              </h2>
              <div className="flex border-2 border-slate-300 rounded-xl  mb-2 leading-tight file:w-72 items-center">
                <input
                  className="py-2 px-3 text-gray-700 w-64 rounded-xl  focus:outline-none"
                  name="password"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="mot de passe"
                  onChange={handleChange}
                />
                <img
                  src={
                    passwordVisible
                      ? "/images/eye.svg"
                      : "/images/eye_unlock.svg"
                  }
                  style={{ height: "22px" }}
                  alt="close"
                  loading="lazy"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <img
                src="/images/fade-stagger-circles.svg"
                style={{ height: "100px" }}
                alt="close"
                loading="lazy"
              />
            </div>
          )}
          {loginError && (
            <div className="text-center text-red-500 text-sm">
              <a href="/reset-password-request">Mot de passe ou email erroné</a>
            </div>
          )}
          <div className="text-end text-slate-500 text-sm pb-2">
            <a href="/reset-password-request">Mot de passe oublié ?</a>
          </div>
          <div className="flex justify-end items-end mt-4">
            <button
              className="bg-sumo-550 hover:opacity-60 rounded-xl py-2 px-4 text-sumo-50 font-semibold"
              type="submit"
            >
              <p>Connexion</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Connexion
