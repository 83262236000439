import { Navigate, useLocation } from "react-router-dom"
import { AuthStatus, useAuth } from "../../hooks/useAuth"

const PrivateRoute = (props: {
  children: React.ReactNode
}): React.ReactElement => {
  const { children } = props
  const { status } = useAuth()
  const location = useLocation()

  return status === AuthStatus.Authenticated ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/login"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  )
}

export default PrivateRoute
