import { Chart, registerables } from "chart.js"
import "chart.js/auto"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import {
  useBaselineScoresByPatients,
  useScoresByMonth,
  usePatientsCount,
  usePatientsSurgeryDates,
  useScoresCount,
  useSubmissionsCount
} from "../../hooks/useStudies"

Chart.register(...registerables)

const Stats = () => {
  const { data: patientCount } = usePatientsCount()
  const { data: scoresCount } = useScoresCount()
  const { data: surveysAnsweredCount } = useSubmissionsCount()
  const { data: baselineScores } = useBaselineScoresByPatients()
  const { data: scoreByMonth } = useScoresByMonth()
  const { data: patientsByDate } = usePatientsSurgeryDates()

  const [chartData, setChartData] = useState({
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        label: "Utilisateurs par date de chirurgie",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          "rgba(56, 226, 203, 0.2)",
          "rgba(56, 215, 226, 0.2)",
          "rgba(56, 198, 226, 0.2)",
          "rgba(56, 181, 226, 0.2)",
          "rgba(56, 147, 226, 0.2)"
        ],
        borderColor: ["rgba(201, 203, 207)"],
        borderWidth: 1
      }
    ]
  })

  useEffect(() => {
    setChartData({
      labels: patientsByDate ? Object.keys(patientsByDate) : [],
      datasets: [
        {
          label: "Patients par mois",
          data: patientsByDate
            ? Object.entries(patientsByDate).map(([key, val]) => {
                return val.length
              })
            : [],
          backgroundColor: [
            "rgba(56, 226, 203, 0.2)",
            "rgba(56, 215, 226, 0.2)",
            "rgba(56, 198, 226, 0.2)",
            "rgba(56, 181, 226, 0.2)",
            "rgba(56, 147, 226, 0.2)"
          ],
          borderColor: ["rgba(201, 203, 207)"],
          borderWidth: 1
        }
      ]
    })
  }, [patientsByDate])

  return (
    <div className="flex w-full md:w-full bg-sumo-100 flex-1 flex-wrap  justify-center items-center">
      <div className="flex md:w-full flex-row justify-center items-center">
        <section className="flex pt-3 md:px-3 flex-col">
          <div className="text-slate-900 font-semibold">
            STATISTIQUES PATIENTS
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Plateforme SuMO
                </div>
              </div>
              <div className="flex flex-row md:flex-col">
                <div className="flex md:w-40 md:h-32 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {patientCount}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Patients inscrits
                  </div>
                </div>
                <div className="flex md:w-40 md:h-36 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-550 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {scoresCount}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Scores calculés
                  </div>
                </div>
                <div className="flex md:w-40 md:h-40 w-28 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {surveysAnsweredCount}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Questionnaires répondus
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:px-2">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Scores pré-op
                </div>
              </div>
              <div className="flex flex-row md:flex-col">
                <div className="flex md:w-40 md:h-32 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {baselineScores &&
                    !isNaN(_.meanBy(baselineScores, "life_quality"))
                      ? (
                          100 - _.meanBy(baselineScores, "life_quality")
                        ).toPrecision(3)
                      : "-"}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    ODI
                  </div>
                </div>
                <div className="flex md:w-40 md:h-36 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-550 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {baselineScores &&
                    !isNaN(_.meanBy(baselineScores, "physical_autonomy"))
                      ? _.meanBy(
                          baselineScores,
                          "physical_autonomy"
                        ).toPrecision(3)
                      : "-"}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Autonomie
                  </div>
                </div>
                <div className="flex md:w-40 md:h-40 w-28 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {baselineScores &&
                    !isNaN(_.meanBy(baselineScores, "pain_management"))
                      ? _.meanBy(baselineScores, "pain_management").toPrecision(
                          3
                        )
                      : "-"}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Gestion douleur
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:px-2">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Scores @3mois
                </div>
              </div>
              <div className="flex flex-row md:flex-col">
                <div className="flex md:w-40 md:h-32 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {scoreByMonth &&
                    scoreByMonth["@3"] &&
                    !isNaN(_.meanBy(scoreByMonth["@3"], "life_quality"))
                      ? (
                          100 - _.meanBy(scoreByMonth["@3"], "life_quality")
                        ).toPrecision(3)
                      : "..."}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    ODI
                  </div>
                </div>
                <div className="flex md:w-40 md:h-36 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-550 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {scoreByMonth &&
                    scoreByMonth["@3"] &&
                    !isNaN(_.meanBy(scoreByMonth["@3"], "physical_autonomy"))
                      ? _.meanBy(
                          scoreByMonth["@3"],
                          "physical_autonomy"
                        ).toPrecision(3)
                      : "..."}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Autonomie
                  </div>
                </div>
                <div className="flex md:w-40 md:h-40 w-28 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {scoreByMonth &&
                    scoreByMonth["@3"] &&
                    !isNaN(_.meanBy(scoreByMonth["@3"], "pain_management"))
                      ? _.meanBy(
                          scoreByMonth["@3"],
                          "pain_management"
                        ).toPrecision(3)
                      : "..."}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Gestion douleur
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:px-2">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Scores @6mois
                </div>
              </div>
              <div className="flex flex-row md:flex-col">
                <div className="flex md:w-40 md:h-32 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {scoreByMonth &&
                    scoreByMonth["@6"] &&
                    !isNaN(_.meanBy(scoreByMonth["@6"], "life_quality"))
                      ? (
                          100 - _.meanBy(scoreByMonth["@6"], "life_quality")
                        ).toPrecision(3)
                      : "..."}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    ODI
                  </div>
                </div>
                <div className="flex md:w-40 md:h-36 w-24 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-550 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {scoreByMonth &&
                    scoreByMonth["@6"] &&
                    !isNaN(_.meanBy(scoreByMonth["@6"], "physical_autonomy"))
                      ? _.meanBy(
                          scoreByMonth["@6"],
                          "physical_autonomy"
                        ).toPrecision(3)
                      : "..."}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Autonomie
                  </div>
                </div>
                <div className="flex md:w-40 md:h-40 w-28 h-24 flex-col rounded-md md:px-4 md:py-4 bg-sumo-500 opacity-90 md:mt-4 mt-2 px-2 justify-center ml-2 md:ml-0">
                  <div className="font-bold md:text-5xl text-2xl text-slate-50">
                    {scoreByMonth &&
                    scoreByMonth["@6"] &&
                    !isNaN(_.meanBy(scoreByMonth["@6"], "pain_management"))
                      ? _.meanBy(
                          scoreByMonth["@6"],
                          "pain_management"
                        ).toPrecision(3)
                      : "..."}
                  </div>
                  <div className="font-bold text-slate-50 md:mt-2 text-xs">
                    Gestion douleur
                  </div>
                </div>
              </div>
            </div>
            <div className="md:pl-4 my-4 md:my-auto justify-center items-center w-[100%] md:w-[800px]">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 text-center">
                  Inclusions par dates
                </div>
              </div>
              <Bar
                data={chartData}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Patients par date de chirurgie"
                    },
                    legend: {
                      display: false
                    }
                  }
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Stats
