import { ObjectType } from "typescript"
import { IPatientSurvey } from "../../types/types"

interface ISurveyListCard {
  patientSurvey: IPatientSurvey
  openSurvey: () => void
}

const surveyNames = {
  professional_lifestyle: "Mode de vie Professionel",
  personal_lifestyle: "Mode de vie personnel",
  medical_history: "Histoire de santé",
  medical_information: "Informations médicales",
  imaging_report: "Compte rendu d'imagerie",
  life_quality: "ODI",
  pain_walk_scale: "Échelles de douleurs et de marche",
  pain_management: "Gestion de la douleur",
  back_to_normal: "Retour à la vie active",
  satisfaction: "Satisfaction de l'app",
  drug_consumption: "Consommation médicamenteuse"
}

const SurveyListCard = (props: ISurveyListCard) => {
  return (
    <div className="flex flex-col w-96 rounded-xl px-4 py-4 bg-sumo-50 shadow-xl">
      <div className="font-bold text-sumo-500">
        {
          surveyNames[
            props.patientSurvey.survey_reference as keyof typeof surveyNames
          ]
        }
      </div>
      <div className="font-normal text-slate-500">
        Répondu le{" "}
        {new Date(props.patientSurvey.submission.created_at).toLocaleDateString(
          "fr"
        )}
      </div>
      <div className="flex justify-end">
        <button className="flex" onClick={() => props.openSurvey()}>
          <div className="bg-sumo-500 rounded-2xl mt-2 text-center justify-center px-8 py-2">
            <div className="text-sumo-50">Voir</div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default SurveyListCard
