import { useQuery } from "@tanstack/react-query"
import { ISurvey } from "../types/types"
import { apiFetchProtected } from "../utils/api"

const fecthSurveys = async (): Promise<Array<ISurvey>> =>
  apiFetchProtected<Array<ISurvey>>("/surveys", {}).then((data) => {
    return data
  })

const useSurveys = <TData = Array<ISurvey>>(
  select?: (data: Array<ISurvey>) => TData
) => {
  return useQuery({
    queryKey: ["surveys"],
    queryFn: () => fecthSurveys(),
    select
  })
}
export { useSurveys }
