import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"

interface IProps {}

const ResetPassword = (props: IProps) => {
  const navigate = useNavigate()
  const { resetPassword, passwordResetted, loading } = useAuth()
  const [inputs, setInputs] = useState({
    password: ""
  })
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [searchParams] = useSearchParams()

  const reset_password_token = searchParams.get("reset-password-token") // "text"

  const resetPasswordRequestForm = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    const password = inputs["password"]
    resetPassword(password, reset_password_token)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name
    const value = e?.target?.value
    setInputs((values) => ({ ...values, [name]: value }))
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  useEffect(() => {
    if (passwordResetted) navigate("/")
  }, [passwordResetted, navigate])

  return (
    <div className="flex items-center justify-center bg-sumo-100 w-full h-screen">
      <div className="rounded-xl bg-sumo-50 shadow-2xl p-4 pt-4">
        <div className="flex items-center  justify-center my-4">
          <img
            src={"/images/sumo_logo_bare.png"}
            style={{ height: "80px" }}
            alt="RP Logo"
            loading="lazy"
            className="bg-sumo-500 rounded-3xl"
          />
        </div>
        <h1 className="font-semibold text-2xl text-sumo-500  text-center">
          Nouveau mot de passe
        </h1>
        <div className="flex justify-center items-cetnter w-full">
          <h2 className="text-lg text-sumo-500 mt-8 text-center w-72">
            Entrez votre nouveau mot de passe pour le réinitialiser.
          </h2>
        </div>
        <form className="m-6" onSubmit={resetPasswordRequestForm}>
          {!loading ? (
            <>
              <h2 className="font-semibold text-lg text-sumo-500 pb-2">
                mot de passe
              </h2>
              <div className="flex border-2 border-slate-300 rounded-xl mb-2 leading-tight file:w-72 items-center pr-2">
                <input
                  className="py-2 px-3 text-gray-700 w-64 rounded-xl  focus:outline-none"
                  name="password"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="mot de passe"
                  onChange={handleChange}
                />
                <img
                  src={
                    passwordVisible
                      ? "/images/eye.svg"
                      : "/images/eye_unlock.svg"
                  }
                  style={{ height: "22px" }}
                  alt="close"
                  loading="lazy"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <img
                src="/images/fade-stagger-circles.svg"
                style={{ height: "100px" }}
                alt="close"
                loading="lazy"
              />
            </div>
          )}
          <div className="flex justify-end items-end mt-4">
            <button
              className="bg-sumo-550 hover:opacity-60 rounded-xl py-2 px-4 text-sumo-50 font-semibold"
              type="submit"
            >
              <p>Soumettre</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
