import { useEffect, useState } from "react"
import PatienCard from "../../components/cards/PatientCard"
import PatientListCard from "../../components/cards/PatientListCard"
import SurveyListCard from "../../components/cards/SurveyListCard"
import SurveyModal from "../../components/cards/SurveyModal"
import {
  orderPatientsByDate,
  usePatientsFilter,
  usePredictionByPatients,
  useScoresByPatients,
  useStudyQuery
} from "../../hooks/useStudies"
import {
  usePostOpSurveys,
  usePreOpSurveys,
  useQOLSurveys,
  useStudyQueryDetails
} from "../../hooks/useStudyDetails"
import { useSurveys } from "../../hooks/useSurveys"
import { IPatientSurvey } from "../../types/types"

interface IProps {}

const Files = (props: IProps) => {
  const { data: patientScores } = useScoresByPatients()
  const { data: patientPredictions } = usePredictionByPatients()
  const { data: study } = useStudyQuery(orderPatientsByDate)

  const [patientId, setPatientId] = useState(
    study?.patients.length ? study?.patients[0].id.toString() : ""
  )

  const { data } = useStudyQueryDetails(patientId || "")
  const { data: preOpSurveys } = usePreOpSurveys(patientId || "")
  const { data: qOLSurveys } = useQOLSurveys(patientId || "")
  const { data: postOpSurveys } = usePostOpSurveys(patientId || "")
  const { data: surveysDefinition } = useSurveys()

  const [surgeryDate, setSurgeryDate] = useState(new Date())
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [surveyFilter, setSurveyFilter] = useState("pre-op")
  const [showSurveyModal, setShowSurveyModal] = useState(false)
  const [patientSurvey, setPatientSurvey] = useState({} as IPatientSurvey)
  const [patientsFilter, setPatientsFilter] = useState("")
  const [patientsFilterDebounced, setPatientsFilterDebounced] = useState("")
  const { data: patientsFiltered } = usePatientsFilter(patientsFilterDebounced)
  const [patientsOrder, setPatientsOrder] = useState(false)
  const [filteredAndOrderedPatients, setFilteredAndOrderedPatients] =
    useState(patientsFiltered)

  useEffect(() => {
    setPatientId(study?.patients.length ? study?.patients[0].id.toString() : "")
  }, [study])

  useEffect(() => {
    if (data) {
      setSurgeryDate(new Date(data?.patient.surgery_date))
      setName(data?.patient.name)
      setSurname(data?.patient.surname)
    }
  }, [patientId, data, data?.patient.surgery_date])

  useEffect(() => {
    const timeOutId = setTimeout(
      () => setPatientsFilterDebounced(patientsFilter),
      500
    )
    return () => clearTimeout(timeOutId)
  }, [patientsFilter])

  useEffect(() => {
    if (patientsOrder)
      if (patientsFiltered) {
        setFilteredAndOrderedPatients(
          [...patientsFiltered].sort((a, b) => {
            const a_date = new Date(a.surgery_date)
            const b_date = new Date(b.surgery_date)
            return a_date.valueOf() - b_date.valueOf()
          })
        )
      } else setFilteredAndOrderedPatients(patientsFiltered)
    else setFilteredAndOrderedPatients(patientsFiltered)
  }, [patientsFiltered, patientsOrder])

  const renderFilteredSurveys = () => {
    let surveys = [] as Array<IPatientSurvey>
    switch (surveyFilter) {
      case "pre-op":
        if (preOpSurveys) surveys = preOpSurveys
        break
      case "post-op":
        if (postOpSurveys) surveys = postOpSurveys
        break
      case "qol":
        if (qOLSurveys) surveys = qOLSurveys
        break
      default:
        if (data?.patient_surveys) surveys = data.patient_surveys
    }
    return surveys.map((survey) => (
      <div className="mb-4">
        <SurveyListCard
          patientSurvey={survey}
          openSurvey={() => {
            setShowSurveyModal(true)
            setPatientSurvey(survey)
          }}
        />
      </div>
    ))
  }

  const pickSurvey = (surveyReference: string) => {
    if (surveysDefinition) {
      const _survey = surveysDefinition?.find(
        (_surv) => _surv.survey_reference === surveyReference
      )
      return _survey
    }
  }

  return (
    <div className="flex w-full justify-center items-center bg-sumo-100 flex-1 flex-wrap">
      <div className="flex bg-sumo-100 py-12">
        <div className="flex w-[250px] flex-col py-4 mx-12">
          <div className="flex flex-row">
            <div className="text-sumo-500 font-semibold pb-4 text-start">
              PATIENTS
            </div>
            <button
              className="flex w-36 items-start justify-end"
              title="ordonner les patients par date"
              onClick={() =>
                setPatientsOrder((patientsOrder) => !patientsOrder)
              }
            >
              <img
                src={"/images/double-arrow.svg"}
                style={{ height: "25px" }}
                alt="logout"
                loading="lazy"
              />
            </button>
          </div>
          <input
            className="mb-4 p-2 rounded-xl mr-5"
            placeholder="filtrez par nom"
            onChange={(event) => setPatientsFilter(event.target.value)}
          ></input>
          <div className="flex pr-3 max-h-[640px] overflow-y-scroll no-scrollbar">
            <ul className="flex flex-col">
              {study !== undefined &&
                filteredAndOrderedPatients?.map((_patient, idx) => (
                  <li className="mb-3" key={_patient.id.toString()}>
                    <PatientListCard
                      patient={_patient}
                      changePatientId={() =>
                        setPatientId(_patient.id.toString())
                      }
                      selected={_patient.id.toString() === patientId}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <PatienCard
          patientId={patientId}
          patientName={name}
          patientSurname={surname}
          surgeryDate={surgeryDate}
          patient_surveys={data?.patient_surveys}
          preOpSurveys={preOpSurveys}
          qOLSurveys={qOLSurveys}
          postOpSurveys={postOpSurveys}
          patientScores={patientScores}
          predictions={patientPredictions}
        />
        <div className="flex w-[400px] flex-col mx-12 py-4 max-h-[760px]">
          <div className="text-sumo-500 font-semibold pb-4">QUESTIONNAIRES</div>
          <div className="flex-row flex pb-4 text-center text-xs">
            <button
              className={`rounded-3xl ${
                surveyFilter === "pre-op" ? "bg-sumo-550" : "bg-sumo-500"
              } mr-4 py-1 px-4`}
              onClick={() => setSurveyFilter("pre-op")}
            >
              <div className="text-sumo-100 font-semibold">PRE-OP</div>
            </button>
            <button
              className={`rounded-3xl ${
                surveyFilter === "post-op" ? "bg-sumo-550" : "bg-sumo-500"
              } mr-4 py-1 px-4`}
              onClick={() => setSurveyFilter("post-op")}
            >
              <div className="text-sumo-100 font-semibold">POST-OP</div>
            </button>
            <button
              className={`rounded-3xl ${
                surveyFilter === "qol" ? "bg-sumo-550" : "bg-sumo-500"
              } py-1 px-4`}
              onClick={() => setSurveyFilter("qol")}
            >
              <div className="text-sumo-100 font-semibold">QUALITÉ DE VIE</div>
            </button>
          </div>
          <div className="overflow-y-scroll no-scrollbar">
            {renderFilteredSurveys()}
          </div>
        </div>
      </div>
      {showSurveyModal ? (
        <SurveyModal
          closeModal={() => setShowSurveyModal(false)}
          survey={pickSurvey(patientSurvey.survey_reference)}
          patientSurvey={patientSurvey}
        />
      ) : null}
    </div>
  )
}

export default Files
